import React from 'react';
import Table from '../components/competititons/Table';

const Competitions = () => {
  const title = 'Terminarz zawodów 2025';
  return (
    <div className='competitions'>
      <h2 className='competitions__title'>{title}</h2>
      <Table className='competitions__table' />
    </div>
  );
};

export default Competitions;
